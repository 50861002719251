<template>
  <section class="result-page">
    <div class="el-icon-circle-check xa-txt-blue"></div>
    <div class="result-tip">优惠券创建成功</div>
    <div class="result-btn">
      <el-button type="primary" @click="toQrCode">立即推广</el-button>
    </div>
    <div class="xa-cell">
      <el-button type="text" class="tip-btn" @click="toCreate"
        >新建单品券</el-button
      >
      <el-button type="text" class="tip-btn" @click="$emit('list')"
        >返回优惠券列表</el-button
      >
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    toQrCode() {
      this.$emit('qrCode', this.$route.query.guid)
    },
    toCreate() {
      this.$router.replace({
        name: 'coupon-create-2'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.result-page {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .el-icon-circle-check {
    font-size: 80px;
    margin-bottom: 26px;
  }
  .result-tip {
    height: 32px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    text-align: center;
    margin-bottom: 51px;
  }
  .result-btn {
    margin-bottom: 36px;
  }
  .tip-btn {
    width: 7em;
  }
}
</style>
